<template>
  <b-card no-body>
    <div class="m-2">
      <b-row class="justify-content-end">

        <!-- Search -->
        <!-- <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->

        <!-- Entry -->
        <!-- <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
            <b-button
                variant="primary"
                :to="{ name: 'apps-transactions-journalTemplate-add'}"
            >
                <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.purchase.postingAp.actions.create') }}
            </b-button>
        </b-col> -->
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

    <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: form number -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-ap-posting-detail', params: { id: item.id } }"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Type -->
      <template #cell(form_type)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap"> 
          {{ (item.contact) ? item.contact.label : 'Transfer' }}
        </span>
        <small class="font-italic">
          {{ item.form_type }}
          <span class="font-weight-bolder text-primary "> - {{ companyActive.plan_id > 2 ? (item.office ? item.office.label : item.account.account_name) : item.account.account_name }}</span>
        </small>
      </template>

      <!-- Column: Date -->
      <template #cell(date)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.date }}
        </span>
      </template>

      <!-- Column: Inv Number -->
      <template #cell(inv_number)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.code }}
        </span>
      </template>

      <!-- Column: description-->
      <!-- <template late #cell(description)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.description }}
        </span>
      </template> -->

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.amount > 0) ? 'text-success' : 'text-danger' ]">
          {{ formatCurrency(item.amount) }}
        </span>
      </template>

      <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>


<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import useListTablePage from '@/comp-functions/useListTablePage'
import { onMounted, ref } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    TableSearchColumn
  },


  setup () {
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage = 30,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    } = useListTablePage({ url: 'transaction/purchase/posting-ap' })

    const tableColumns = ref([
      {
        key: 'code',
        label: 'Form Number',
        search: { type: 'text', value: '' }
      },
      {
        key: 'date',
        label: $t('globalSingular.date'),
        sortable: true,
        formatter: (value) => value || '-',
        search: { type: 'date', value: '' }
      },
      {
        key: 'office.label',
        label: $t('globalSingular.branch'),
        thStyle: { minWidth: '200px' },
        formatter: (value) => value || '-',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'form_type',
        label: $t('globalSingular.contact'),
        search: {
          type: 'text',
          value: '',
          key: 'form_type_label'
        }
      },
      {
        key: 'no_invoices',
        label: 'Inv Num',
        thStyle: { minWidth: '200px' },
        formatter: (value) => value || '-',
        search: { type: 'text', value: '' }
      }, 
      {
        key: 'description',
        label: $t('globalSingular.description'),
        thStyle: { minWidth: '300px' },
        formatter: (value) => value || '-',
        search: { type: 'text', value: '' }
      },
      {
        key: 'amount',
        label: 'Req Amount',
        thStyle: { minWidth: '300px' },
        formatter: (value) => value || '-',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'updatedBy.user_name',
        label: $t('globalSingular.requester'),
        search: {
          type: 'text',
          value: '',
          key: 'updated_user'
        }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        search: { type: 'text', value: '' }
      }
    ])

    onMounted(() => {
      changePerPage(30)
    })

    return {
      formatCurrency,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    }
  },


  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>